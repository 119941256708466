import { TechnicalError } from '@orus.eu/error'
import { useTranslate } from '@orus.eu/pharaoh'
import type { ParameterlessTranslationKey } from '@orus.eu/translations'
import { useEffect, useState } from 'react'

/**
 *
 * @param explanationsTranslationKey The key translation is expected to contains pipe-separated individual explanations that are
 * displayed one after the other.
 * Example (fake): "loading_explanations_receive_quote" => "Génération de votre nouveau contrat...|Ne vous inquiétez pas, cela peut prendre un peu de temps...|Encore un peu de patience..."
 * @param explanationDuration
 * @returns
 */
export function useTimedExplanations(
  explanationsTranslationKey: ParameterlessTranslationKey,
  explanationDuration: number,
): string {
  const translate = useTranslate()

  const explanations = translate(explanationsTranslationKey)
    .split('|')
    .map((s) => s.trim())

  if (explanations.length < 2) {
    throw new TechnicalError(
      'Invalid useTimedExplanations parameters: translated explanations keymust contain at least two explanations',
      { context: { explanationsTranslationKey } },
    )
  }

  const [currentIndex, setCurrentIndex] = useState<number>(0)

  useEffect(() => {
    // Change explanation every two seconds
    const interval = setInterval(() => setCurrentIndex((index) => index + 1), explanationDuration)

    return () => clearInterval(interval)
  }, [explanationDuration])

  return currentIndex < explanations.length ? explanations[currentIndex] : explanations[explanations.length - 1]
}
